import React, {useEffect} from 'react';

import { Grid, withStyles, Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnUtils from '@date-io/date-fns';

const Picker = withStyles(theme => ({
    root: {
        margin: theme.spacing(2)
    }
 }))(Grid);

const StartEndDate = ({ startDate, endDate, onChangeStartDate, onChangeEndDate, onRefresh, time=1000*60*5, ...rest }) => {
    useEffect(()=>{
        const interval = setInterval(()=>{
            onRefresh()
        }, time);

        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid xs={12} item container alignItems="center" {...rest}>
            <MuiPickersUtilsProvider utils={DateFnUtils}>
                <Picker item>
                    <DateTimePicker 
                        ampm={false}
                        value={startDate}
                        onChange={onChangeStartDate}
                        format="dd/MM/yyyy HH:mm"
                        label="Desde:"/>
                </Picker>
                <Picker item>
                    <DateTimePicker 
                        ampm={false}
                        value={endDate}
                        onChange={onChangeEndDate}
                        format="dd/MM/yyyy HH:mm"
                        label="Hasta:"/>
                </Picker>
                {onRefresh && (
                    <Button 
                        color="primary"
                        onClick={onRefresh}>
                        <Check style={{marginRight: 16}}/> Aplicar
                    </Button>
                ) }
            </MuiPickersUtilsProvider>
        </Grid>
    );
}

export default StartEndDate;