import React, {  useMemo } from 'react';

const NotificationContext = React.createContext();

export const NotificationProvider = (props) => {

    const value = useMemo(() => ({
        
    }), []);

    return <NotificationContext.Provider value={value} {...props} />
}

export const useNotificationContext = () => { 
    const context = React.useContext(NotificationContext);
    
    if (!context) { 
        throw new Error("useNotificationContext() must be inside of NotificationProvider")
    }

    return context;
}