import React from 'react';

import classes from './NotFound.module.css';

import { Button, useTheme } from '@material-ui/core';
import NotFoundLogo from '@internal/assets/img/error.png';
import { withRouter } from 'react-router-dom'; 

const NotFound = ({ history, isFullPage }) => {
    const theme = useTheme();

    const mainClasses = [classes.main];
    if (isFullPage) mainClasses.push(classes.fullPage);

    return (
        <main className={mainClasses.join(" ")}>
            <div className={classes["container"]}>
                <figure className={classes["img-container"]}>
                    <img src={NotFoundLogo} alt="Buho Error 404"/>
                </figure>
                <div className={classes["text-container"]}>
                    <h1 style={{color: theme.palette.error.main}}>
                        Error 404
                    </h1>
                    <p>
                        Lo sentimos, el enlace al que quieres acceder no existe o ya no se encuentra disponible.
                    </p>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={()=>history.push("/")}>
                        Volver al inicio
                    </Button>    
                </div>
            </div>
        </main>
    );
}

export default withRouter(NotFound);