import React, { useState, useEffect } from 'react';

import { Paper, Tab, Tabs } from '@material-ui/core';

import TabPanel from '@internal/components/TabPanel/TabPanel';
import TempForm from '@internal/components/TempForm/TempForm';

import { withRole } from '@internal/hoc/roles';
import { ROLES, API } from '@internal/constants';
import { getTempQueue, setTempQueue } from '@internal/helpers/tempQueue';

import classes from './TempRegister.module.css';

import { useUserContext } from '@internal/contexts/user.context';
import { useConfigContext } from '@internal/contexts/config.context';
import { useSnackbar } from 'notistack';
import TempQueue from '@internal/components/TempQueue/TempQueue';

const TempRegister = props => {
    const [currentTab, setCurrentTab] = useState(0);
    const [entrances, setEntrances] = useState([]);
    const [tempQueue, setQueue] = useState(getTempQueue());

    const { token } = useUserContext();
    const { startLoading, stopLoading } = useConfigContext();
    const { enqueueSnackbar } = useSnackbar();

    const handleTabChange = (event, newValue) => setCurrentTab(newValue);

    const semanticProps = (i) => ({
        id: `simple-tab-${i}`,
        "aria-controls": `simple-tabpanel-${i}`,
    });

    useEffect(() => { 
        const getEntrances = async () => {
            const queries = new URLSearchParams({
                page: 0, limit: 100
            });
    
            const url = new URL(`${API.BASEURL}/entrance/all`);
            url.search = queries.toString()

            const response = await fetch(url, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });

            if (response.ok) { 
                const { data } = await response.json();
                data.sort((a, b) => a.name.localeCompare(b.name));  
                setEntrances(data.filter(entrance => entrance.active));
            } else { 
                enqueueSnackbar("Hubo un error obteniendo los puntos de acceso", { variant: "error" });
            }
        };

        getEntrances();
    }, [token, enqueueSnackbar]);

    /**
     * Register temperature methods
     */

    const onSubmitNTHandler = (data, toQueue=false) => { 
        if (!toQueue) {
            saveTemperature(data);
        } else { 
            pushToQueue(data);
        }
    }

    const saveTemperature = async ({ code, temperature, timestamp, maskGiven, comment, entrance, organization }) => {
        startLoading();
        try {
            const body = JSON.stringify({ code, temperature, timestamp, maskGiven, comment, entrance, organization });

            const response = await fetch(`${API.BASEURL}/temp/register`, {
                body,
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-type": "application/json"
                },
                method: "POST"
            })

            if (response.ok) {
                enqueueSnackbar("Temperatura registrada con éxito", { variant: "success" });
            } else { 
                enqueueSnackbar("Ups... Ocurrió un error guardando el registro", { variant: "error" });
            }
        } catch (error) {
            enqueueSnackbar("Ups... Ocurrió un error guardando el registro", { variant: "error" });
        }
        stopLoading();
    }

    const pushToQueue = ({ code, temperature, timestamp, maskGiven, comment, entrance, organization }) => { 
        const temperatureLog = {
            id: `${code}_${timestamp}_${Math.random()}`,
            code, temperature, timestamp, maskGiven, comment, entrance, organization
        }

        const newQueue = [temperatureLog, ...tempQueue];
        setTempQueue(newQueue);
        setQueue(newQueue)

        enqueueSnackbar("Temperatura añadida a la cola", { variant: "success" });
    }

    const editTempInQueue = ({ id, code, temperature, timestamp, maskGiven, comment, entrance, organization }) => { 
        const newQueue = [...tempQueue];
        
        const index = newQueue.findIndex(log => log.id === id);
        if (index < 0) { 
            enqueueSnackbar("No se encontró el registro de temperatura", { variant: "error" });
            return; 
        }

        newQueue.splice(index, 1);
        
        const temperatureLog = {
            id,
            code, temperature, timestamp, maskGiven, comment, entrance, organization
        }

        newQueue.unshift(temperatureLog);

        setTempQueue(newQueue);
        setQueue(getTempQueue())

        enqueueSnackbar("Temperatura modificada", { variant: "success" });
    }

    const deleteFromQueue = (id) => { 
        const newQueue = [...tempQueue];
        
        const index = newQueue.findIndex(log => log.id === id);
        if (index < 0) { 
            enqueueSnackbar("No se encontró el registro de temperatura", { variant: "error" });
            return; 
        }

        newQueue.splice(index, 1);

        setTempQueue(newQueue);
        setQueue(getTempQueue())

        enqueueSnackbar("Temperatura eliminada", { variant: "success" });
    }

    const syncQueue = async () => { 
        startLoading();
        const queue = [...tempQueue];
        
        const body = JSON.stringify({temperatures: queue});
        const response = await fetch(`${API.BASEURL}/temp/register/many`, {
            body,
            method: "POST",
            headers: {
                authorization: `Bearer ${token}`,
                "Content-type": "application/json"
            }
        });

        if (response.ok) {
            const results = await response.json();
            const newQueue = queue.filter(log => {
                const index = results.findIndex(result => result.id === log.id);
                return !results[index].syncronized;
            });

            setTempQueue(newQueue);
            setQueue(getTempQueue());

            if (newQueue.length === 0) { 
                enqueueSnackbar("Todos los datos ha sido sincronizados", { variant: "success" });
            }

            if (newQueue.length === queue.length) {
                enqueueSnackbar("No se sincronizó ningun dato...", { variant: "error" });
            } 
            
            if(newQueue.length > 0 && newQueue.length < queue.length) { 
                enqueueSnackbar("Se sincronizaron algunos datos", { variant: "warning" });
            }
        } else { 
            enqueueSnackbar("Ups... Error al sincronizar", { variant: "error" });
        }

        stopLoading();
    }
 
    return (
        <main className={classes.container}>
            <h1> Ingreso de temperaturas </h1>
            <Paper className={classes["tab-container"]} elevation={3} >
                <Paper square elevation={1}>
                    <Tabs value={currentTab}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        textColor="primary"
                        indicatorColor="primary">

                        <Tab label="Nueva Temperatura" {...semanticProps(0)}/>
                        <Tab label="Cola de Temperaturas" {...semanticProps(1)}/>
                    </Tabs>
                </Paper>
                <TabPanel index={0} value={currentTab}>
                    <TempForm
                        onSubmit={(data)=>onSubmitNTHandler(data)}
                        onQueue={(data)=>onSubmitNTHandler(data, true)}
                        entrances={entrances} />
                </TabPanel>

                <TabPanel index={1} value={currentTab}>
                    <TempQueue
                        onUpdate={(data) => { editTempInQueue(data) }}
                        onDelete={deleteFromQueue}
                        onSync={syncQueue}
                        entrances={ entrances }
                        queue={tempQueue} />
                </TabPanel>
            </Paper>
        </main>
    )
}

export default withRole(TempRegister, ROLES.VIGILANT);