import React from 'react';

import { Grid, withStyles, makeStyles, Paper, IconButton } from '@material-ui/core';
import { Edit, Block, Check, DirectionsCar, DirectionsWalk, Delete } from '@material-ui/icons'; 

import { ROLES } from '@internal/constants';
import { withRoleRender } from '@internal/hoc/roles';

const Div = ({ children, classes, className, ...rest }) => { 
    return <div {...rest} className={[classes.root, className].join(" ")} > {children} </div>
}

const Tile = withStyles((theme) => ({
    root: {
        width: "300px",
        height: "175px",
        overflow: "hidden",
        userSelect: "none",
        cursor: "pointer",
        position: "relative",
        color: "white",
    }
}))(Grid);

const DeleteButton = withRoleRender(withStyles(theme => ({
    root: {
        position: 'absolute',
        top: theme.spacing(0),
        right: theme.spacing(0),
        color: theme.palette.common.white
    }
}))(IconButton), ROLES.SYSADMIN);

const TileContainer = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    }
}))(Grid);

const BackgroundContainer = withStyles((theme) => ({
    root: { 
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    }
}))(Div);

const Backdrop = withStyles((theme) => ({
    root: { 
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        opacity: 0.8,
    }
}))(Div);

const MainContainer = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        flexDirection: "column"
    }
}))(Div);

const IconContainer = withStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between"
    }
}))(Div)

const useStyles = active => makeStyles(theme => ({
    backdrop: {
        backgroundColor: active ? theme.palette.primary.dark: "#b71c1c",
    }
}))

const EntranceTile = ({ code, name, photo, active, allowsPeople, allowsVehicles, onClick, onDelete }) => {
    const classes = useStyles(active)();
    const activeIcon = active ? <Check/> : <Block/>

    return (
        <TileContainer item>
            <Tile component={Paper}>
                <BackgroundContainer style={{ backgroundImage: `url(${photo})` }} />
                <Backdrop className={classes.backdrop}/>
                <MainContainer onClick={onClick}>
                    <h3> {code} </h3>
                    <h2 style={{flex: "1 0", margin: 8}}> {name} </h2>
                    
                    <IconContainer>
                        {activeIcon}
                        <div>
                            { allowsPeople && <DirectionsWalk/> }
                            { allowsVehicles && <DirectionsCar/> }
                        </div>
                        <Edit/>
                    </IconContainer>
                </MainContainer>
                <DeleteButton onClick={()=> { onDelete() }}>
                    <Delete/>
                </DeleteButton>
            </Tile>
        </TileContainer>
    );
}

export default EntranceTile;