import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { getToken, removeToken, setToken } from '@internal/helpers/token';
import { getUser, removeUser, setUser } from '@internal/helpers/user';
import { API } from '@internal/constants';
import { useSnackbar } from 'notistack';

import { useConfigContext } from './config.context';

const UserContext = React.createContext();

export const UserProvider = (props) => { 
    const [user, setUserAPI] = useState({});
    const [tokenAPI, setTokenAPI] = useState(getToken());
    const [loginError, setLoginError] = useState(null);
    const [needNewPassword, setPasswordNeeded] = useState(false);
    const [newPassError, setNewPassError] = useState(null);

    const { startLoading, stopLoading } = useConfigContext();
    
    const { enqueueSnackbar } = useSnackbar();

    //Verifiy userExistance
    useEffect(() => { 
        const verifyUser = async () => { 
            try {
                startLoading();

                const token = getToken();
                const user = getUser();

                if (!token || !user) { 
                    logout();
                    stopLoading();
                    return;
                }
                
                const response = await fetch(`${API.BASEURL}/user/whoami`, {
                    headers: {
                        authorization: `Bearer ${token}`
                    },
                    method: "GET"
                });

                if (response.ok) {
                    const data = await response.json();
                    //console.log(data);
                    setTokenAPI(token);
                    setUserAPI({...user, role: data.role});
                    setUser({...user, role: data.role})
                } else { 
                    logout();
                }
            } finally {
                stopLoading();
            }
        }

        verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * TODO: All methods for user logic. Login, logout
     */

    const login = useCallback((code, password, organization) => { 
        const loginAsync = async (code, password, organization) => {
            startLoading();
            try {
                const body = JSON.stringify({ code, password, organization });
                const response = await fetch(`${API.BASEURL}/auth/signin`, {
                    headers: {
                        "Content-type": "application/json"
                    },
                    method: "POST",
                    body
                });
    
                const data = await response.json();
    
                
                if (response.ok) {
                    const { user, token, newPassword } = data;
                    if (newPassword) {
                        setPasswordNeeded({ newPassword, token, code, organization: user.organization });
                        stopLoading();
                        return;
                    }
    
                    setToken(token);
                    setTokenAPI(token);
                    setPasswordNeeded(newPassword);
                    setLoginError(null)
                    setUser(user);
                    setUserAPI(user);
                } else {
                    setLoginError(response.status);
                }
            } catch (error) {
                enqueueSnackbar("Ups... Ocurrió un error", { variant: "error" });
            }

            stopLoading();
        };

        loginAsync(code, password, organization);
    }, [enqueueSnackbar, startLoading, stopLoading]);

    const loginWGoogle = useCallback((googleToken) => {
        const loginWGoogleAsync = async () => { 
            startLoading()
            const body = JSON.stringify({
                googleToken: googleToken
            });

            const response = await fetch(`${API.BASEURL}/auth/google-signin`, {
                body,
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                }
            });

            const data = await response.json();

            if (response.ok) {
                const { user, token } = data;

                setToken(token);
                setTokenAPI(token);
                setUserAPI(user);
                setUser(user);

            } else { 
                enqueueSnackbar("Ups... Ocurrió un error", { variant: "error" });
            }

            stopLoading();
        }

        loginWGoogleAsync();
    }, [enqueueSnackbar, startLoading, stopLoading]);

    const setNewPassword = useCallback((newPassword) => {
        const setNewPasswordAsync = async (password) => {
            try {
                if (needNewPassword) {
                    const { token, code, organization } = needNewPassword;
                    const body = JSON.stringify({
                        token,
                        password
                    });
    
                    const response = await fetch(`${API.BASEURL}/auth/new-password`, {
                        headers: {
                            "Content-type": "application/json"
                        },
                        body,
                        method: "POST"
                    });
    
                    if (response.ok) {
                        setPasswordNeeded(false);
                        setNewPassError(null);
                        await login(code, password, organization);
                    } else {
                        setNewPassError(response.status);
                    }
                }
            } catch (error) {
                enqueueSnackbar("Ups... Ocurrió un error", { variant: "error" });
            }
        };

        setNewPasswordAsync(newPassword);
    }, [needNewPassword, enqueueSnackbar, login])

    const logout = () => {
        removeToken();
        setTokenAPI(null);
        setUserAPI({});
        removeUser();
    };

    const noPassNeeded = () => setPasswordNeeded(false);

    //Value to return -- Must have all the constants and methods to use
    //Use memo change the value only if the declared consts in the array (2nd paramenter) changes
    const value = useMemo(() => {
        return {
            user,
            token: tokenAPI,
            needNewPassword,
            loginError,
            newPassError,
            setNewPassword,
            noPassNeeded,
            login,
            loginWGoogle,
            logout,
        }
    }, [user, tokenAPI, loginError, needNewPassword, newPassError, login, loginWGoogle,setNewPassword]);

    return <UserContext.Provider value={value} {...props} />
}

/**
 * Hook to use user information
 */
export const useUserContext = () => { 
    const context = React.useContext(UserContext);

    if (!context) { 
        throw new Error("useUserContext() must be inside of UserProvider")
    }

    return context;
}