import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    panel: {
        padding: "1rem",
        overflowY: "auto"
    }
}))

const TabPanel = ({ children, value, index, component,...other }) => {
    const classes = useStyles();

    const Component = component || "div";
    
    return (
        index === value && (
            <Component
                {...other}
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                role="tabpanel"
                className={classes.panel}>
                { children }
            </Component>
        )
    );
}

export default TabPanel;