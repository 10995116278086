import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { TextField, useTheme, Button, 
    Paper, FormControl, InputLabel, makeStyles,
    Select, MenuItem, Grid, Collapse, IconButton } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import PasswordField from '@internal/components/PasswordInput/PasswordInput';
import NPDialog from '@internal/components/NPDialog/NPDialog';
import AutoForm from '@internal/components/AutoForm/AutoFrom';

import GoogleLogin from 'react-google-login';

import classes from "./Login.module.css";

import { KEYS } from '@internal/constants';
import { useUserContext } from '@internal/contexts/user.context';
import { useSnackbar } from 'notistack';

import logo from '@internal/assets/img/LogoUCAblanco.png';

const useStyles = makeStyles(theme => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    inputs:{
        "&>*":{
            margin: theme.spacing(1, 0)
        }
    }
}))

const Login = ({ location }) => {
    const { from } = location.state || { from: { pathname: "/app" } }
    const theme = useTheme();
    const classesMT = useStyles();

    const { login, token, loginError, needNewPassword, loginWGoogle } = useUserContext();
    const { enqueueSnackbar } = useSnackbar(); 
    
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [organization, setOrganization] = useState("UCA");

    const [expanded, setExpanded] = useState(false);

    const googleLogin = (response) => {
        const { error, tokenId } = response;
        if (!error) {
            loginWGoogle(tokenId);
        } else { 
            enqueueSnackbar("Ups... Ha acourrido un error", { variant: "error" });
        }
    }

    const onExpandHandler = () => {
        setExpanded(!expanded);
    }

    if (token) {
        return <Redirect to={from} />
    }

    const collapseBtnClasses = [classesMT.expand];
    if(expanded) collapseBtnClasses.push(classesMT.expandOpen);

    let codeError = false;
    let codeErrorMsg = "";
    let passError = false;
    let passErrorMsg = "";

    switch (loginError) {
        case 404:
            codeError = true;
            codeErrorMsg = "Usuario no encontrado"
            break;
        case 401:
            passError = true;
            passErrorMsg = "Contraseña incorecta"
            break;
        case 400:
            codeError = true;
            codeErrorMsg = "Campo requerido"
            break;
        default:
            break;
    }

    const submitForm = () => login(code, password === "" ? undefined : password, organization);

    return (
        <div className={classes.container}>
            <Paper className={classes.main} elevation={3}>
                <header className={classes.header} style={{ backgroundColor: theme.palette.primary.main }}>
                    <div className={classes.logo}>
                        <img src={logo} alt="Logo UCA" />
                    </div>
                    <h1> Check In </h1>
                    <h2> Universidad Centroamericana José Simeón Cañas </h2>
                </header>
                <div className={classes["form-div"]}>
                    <h2> 
                        Inicio de sesión 
                        <IconButton className={collapseBtnClasses.join(" ")} onClick={onExpandHandler}>
                            <ExpandMoreIcon/> 
                        </IconButton>  
                    </h2>
                    <AutoForm onSubmit={submitForm} className={classes["login-form"]}>
                        <Grid container spacing={2} alignItems="stretch">
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <Grid item container xs={12} justify="flex-end" className={classesMT.inputs}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            error={codeError}
                                            helperText={ codeErrorMsg }
                                            value={code}
                                            onChange={({ target }) => { setCode(target.value); }}
                                            variant="outlined"
                                            label="Código/Correo" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordField
                                            fullWidth
                                            error={passError}
                                            helperText={ passErrorMsg }
                                            value={password}
                                            onChange={({ target }) => { setPassword(target.value); }}
                                            variant="outlined"
                                            label="Contraseña" />
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="organization-label"> Institución </InputLabel>
                                            <Select
                                                autoWidth={true}
                                                value={organization}
                                                onChange={({ target }) => { setOrganization(target.value) }}
                                                label="Institución"
                                                labelId="organization-label">
                                                <MenuItem value="" disabled> Institución </MenuItem>
                                                {KEYS.ORGANIZATIONS.map(organization => (
                                                    <MenuItem key={organization.code} value={organization.code}>
                                                        {organization.title.length <= 35 ? organization.title : `${organization.title.substr(0, 35)}...`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            onClick={()=>submitForm()}
                                            className={classes.button}
                                            color="primary"
                                            variant="contained">
                                            Iniciar sesión
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
    
                            <Grid xs={12} item style={{padding: 0}}>
                                <GoogleLogin
                                    clientId={KEYS.GOOGLEID}
                                    onSuccess={googleLogin}
                                    onFailure={googleLogin}
                                    theme="dark"
                                    cookiePolicy="single_host_origin"
                                    buttonText="Iniciar sesión con Google"
                                    icon={false}
                                    className={[classes.button, classes.google].join(" ")} />
                            </Grid>

                            <Grid xs={12} item style={{padding: 0}}> 
                                <Button
                                    fullWidth
                                    variant="contained"
                                    style={{backgroundColor: "#000", color: "#fff"}}
                                    className={classes.redirectBtn} 
                                    href="https://play.google.com/store/apps/details?id=com.naldana.checkinuca" target="_blank" rel="noreferrer" >
                                    Obtén la aplicación móvil
                                </Button>
                            </Grid>
                        </Grid>
                    </AutoForm>
                </div>
            </Paper>
            <NPDialog open={needNewPassword ? true : false}/>
        </div>
    );
}

export default Login;