import React from "react";

import { Line } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { getRoleNumber } from "@internal/helpers/roles";
import { ROLES } from "@internal/constants";

import { useUserContext } from '@internal/contexts/user.context';

const TempChart = ({ temperatures=[] }) => {
    const theme = useTheme();
    const { user } = useUserContext();
    const { push } = useHistory();

    const data = {
        labels: temperatures.map((temperature) => moment(temperature.timestamp).format("DD/MM/yy hh:mm")),
        datasets: [
            {
                label: "Temperatura",
                data: temperatures.map((temperature) => Number(temperature.temperature)),
                fill: false,
                backgroundColor: theme.palette.primary.dark,
                borderColor: theme.palette.primary.main,
                borderWidth: 1,
                pointRadius: 2,
                
            }
        ]
    };

    const temps = [...temperatures];
    temps.sort((t1, t2) => t1.temperature - t2.temperature);

    const min = temps[0] ? temps[0].temperature : 34;
    const max = temps[temps.length - 1] ? temps[temps.length - 1].temperature : 34;

    console.log(min, max)


    return (
        <Line data={data} options={{
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        max: Math.max(40, max),
                        min: Math.min(34, min),
                    }
                }],
                xAxes: [{ 
                    ticks: {
                        maxTicksLimit: 10,
                        fontFamily: "Roboto",
                        fontSize: 10
                    }
                }]
            },
            onClick: (e, elements) => { 
                if(elements.length > 0 && getRoleNumber(user.role) > getRoleNumber(ROLES.REPORTERPLUS)) {
                    const { code, organization } = temperatures[elements[0]._index];
                    push("/app/check-person", { code, organization });
                }
            }
        }}/>
    );
};

export default TempChart;
