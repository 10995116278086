import { ROLES } from '@internal/constants';

export const getRoleNumber = (role) => { 
    switch (role) {
        case ROLES.SYSADMIN:
            return 5;
        case ROLES.ADMIN:
            return 4;
        case ROLES.REPORTERPLUS:
            return 3;
        case ROLES.VIGILANT:
            return 2;
        case ROLES.REPORTER:
            return 1;
        case ROLES.USER:
            return 0;
        default:
            return -1;
    }
}