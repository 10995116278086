import React from 'react';
import { NavLink } from 'react-router-dom';

import {
    SwipeableDrawer as Drawer, IconButton, List,
    ListItem, ListItemIcon, ListItemText,
    Divider, makeStyles
} from '@material-ui/core';

import { MenuOpen } from '@material-ui/icons';

import { getRoleNumber } from '@internal/helpers/roles';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: "100%",
        maxWidth: 450,
        flexShrink: 0
    },
    drawerPaper: {
        width: "100%",
        maxWidth: 450
    },
    header: {
        position: "relative",
        height: "25vh",
        minHeight: 200,
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary.main
    },
    closeIcon: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(2),
        color: "#fff"
    },
    link: {
        display: "flex",
        textDecoration: "none",
        color: theme.palette.text.primary
    },
    current: {
        "&>div": {
            backgroundColor: theme.palette.action.selected
        }
    }
}));

const MainDrawer = ({ menusByRole, user, open, closeDrawer, openDrawer }) => {
    const classes = useStyles();
    
    const role = getRoleNumber(user.role);
    const menuList = [];

    Object.keys(menusByRole).forEach(key => { 
        if (key <= role) { 
            const menus = (
                <React.Fragment key={`role_${key}`}>
                    <List>
                        {menusByRole[key].map(menu => {
                            return (
                                <NavLink
                                    key={menu.title}
                                    exact
                                    className={classes.link}
                                    activeClassName={classes.current}
                                    to={menu.path}>
                                    <ListItem
                                        button
                                        onClick={() => { closeDrawer() }}>
                                        <ListItemIcon> <menu.icon /> </ListItemIcon>
                                        <ListItemText primary={menu.title} />
                                    </ListItem>
                                </NavLink>
                            )
                        })}
                    </List>
                    <Divider/>
                </React.Fragment>
            );

            menuList.push(menus)
        }
    })
    
    return (
        <Drawer
            onClose={closeDrawer}
            onOpen={openDrawer}
            open={open}
            variant="temporary"
            anchor="left"
            classes={{paper: classes.drawerPaper}}
            className={classes.drawer}>
            
            <header className={classes.header}>
                <IconButton onClick={closeDrawer} className={classes.closeIcon}>
                    <MenuOpen/>
                </IconButton>
            </header>

            { menuList }
        </Drawer>
    );
}

export default MainDrawer;