import React from 'react';
import moment from "moment";

import { makeStyles, TableCell, TableRow, withStyles, Table, TableHead, TableBody, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
    
    },
    table: {
        "& td": {
            padding: theme.spacing(0.5, 1)
        }
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: props => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        "&:hover": {
            backgroundColor: theme.palette.action.selected,
        }
    }),
  }))(TableRow);

const StyledHead = withStyles((theme) => ({
    root:{
        padding: theme.spacing(1),
        "& h1": {
            fontSize: 20,
            fontWeight: "bolder",
            textAlign: "center"
        },
        "& h2": {
            margin: theme.spacing(0.5, 0),
            fontSize: 18,
            textAlign: "center"
        }
    }
}))(TableRow)

const TempsReport = React.forwardRef(
    ({ temperatures = [], startDate=undefined, endDate=undefined }, ref) => {
        const classes = useStyles();
        const tableBody = temperatures.map((temperature, i) => (
            <StyledTableRow key={temperature._id}>
                <TableCell align="center" style={{width: "5ch"}} >{i + 1}</TableCell>
                <TableCell align="center" style={{width: "13ch"}} >{temperature.code}</TableCell>
                <TableCell align="center">{temperature.organization}</TableCell>
                <TableCell align="center">{Number(temperature.temperature).toFixed(1)} °</TableCell>
                <TableCell>{temperature.entrance}</TableCell>
                <TableCell align="center" style={{width: "20ch"}}>{temperature.takenBy}</TableCell>
                <TableCell align="center">{moment(temperature.timestamp).format("DD/MM/yy HH:mm")}</TableCell>
                <TableCell>{temperature.comment}</TableCell>
            </StyledTableRow>
        ))
    
        return (
            <div ref={ref} className={classes.container}>
                <Table className={classes.table}>
                    <TableHead>
                        <StyledHead>
                            <StyledTableCell colSpan="8" style={{border: "none", textAlign: "justify"}}> 
                                <Typography variant="h1">
                                    Listado de temperaturas registradas dentro del campus del { moment(startDate).format("DD/MM/yy HH:mm") } al { moment(endDate).format("DD/MM/yy HH:mm") }
                                </Typography> 
                                <Typography variant="h2">
                                    Universidad Centroamericana José Simeón Cañas
                                </Typography>
                            </StyledTableCell>
                        </StyledHead>
                        <TableRow>
                            <StyledTableCell align="center"> # </StyledTableCell>
                            <StyledTableCell align="center"> Código </StyledTableCell>
                            <StyledTableCell align="center"> Institución </StyledTableCell>
                            <StyledTableCell align="center"> Temperatura </StyledTableCell>
                            <StyledTableCell align="center"> Punto de control </StyledTableCell>
                            <StyledTableCell align="center"> Tomada por </StyledTableCell>
                            <StyledTableCell align="center"> Fecha </StyledTableCell>
                            <StyledTableCell align="center"> Comentario </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableBody}
                    </TableBody>
                </Table>
            </div>
        );
    }
);



export default TempsReport;