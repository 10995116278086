import React from 'react';

import { Grid } from '@material-ui/core';

import EntranceTile from './EntranceTile/EntranceTile';

const EntranceTiles = ({ entrances, openDialog, openDeleteDialog }) => {

    const entranceComponents = entrances.map(entrance => (
        <EntranceTile
            key={entrance._id}
            onClick={()=> openDialog(entrance)}
            onDelete={()=> openDeleteDialog(entrance)}
            name={entrance.name}
            photo={entrance.photo}
            allowsPeople={entrance.allowsPeople}
            allowsVehicles={entrance.allowsVehicles}
            active={entrance.active}
            code={entrance.code} />
    ));


    return (
        <Grid container justify="center" alignContent="center">
            { entranceComponents }
        </Grid>
    );
}

export default EntranceTiles;