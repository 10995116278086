import React, { useState } from 'react';

import { AppBar, Toolbar, IconButton, Typography, makeStyles, Menu, MenuItem, Badge } from '@material-ui/core';
import { Menu as MenuIcon, AccountCircle as AccountIcon, Person, ExitToApp, Notifications } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    title: {
        flex: "1 0",
        margin: theme.spacing(0, 2),
        pointerEvents: "none"
    },
    accoutContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    },
    code: {
        fontFamily: '"Montserrat"',
        margin: theme.spacing(0, 2),
        userSelect: "none",
        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },
    menuItem: {
        display: "flex",
        justifyContent: "space-between"
    },
    menuText: {
        margin: theme.spacing(0, 2)
    },
    notiBtn: {
        margin: theme.spacing(0,1),
        padding: 0,
    }
}));

const Appbar = ({ title, code, openDrawer, openNotidrawer, logout }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const classes = useStyles();
    
    const openAccountMenu = (e) => setAnchorEl(e.currentTarget);
    const closeAccountMenu = () => { setAnchorEl(null)};
    
    const isMenuOpen = Boolean(anchorEl);

    return (
        <AppBar position="sticky">
            <Toolbar>
                <IconButton onClick={openDrawer} edge="start" color="inherit" aria-label="menu">
                    <MenuIcon/>
                </IconButton>
                <Typography variant="h6" className={ classes.title }>
                    { title }
                </Typography>
                <div
                    aria-controls="menu-account"
                    aria-haspopup="true"
                    onClick={openAccountMenu}
                    className={classes.accoutContainer}
                    >
                    <Typography variant="h6" className={ classes.code }>
                        { code ? code.toUpperCase() : "" }
                    </Typography>
                    <IconButton
                        aria-label="account of current user"
                        color="inherit">
                            <AccountIcon />
                    </IconButton>
                </div>
                <Menu
                    anchorEl={anchorEl}
                    onClose={() => {closeAccountMenu()}}
                    open={isMenuOpen}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id="menu-account">

                    <MenuItem className={classes.menuItem}>
                        <Typography variant="button" className={classes.menuText }> Mi cuenta </Typography>
                        <Person />
                    </MenuItem>
                    <MenuItem className={classes.menuItem} onClick={logout}>
                        <Typography variant="button" className={classes.menuText }> Desconectarse </Typography>
                        <ExitToApp />
                    </MenuItem>
                
                </Menu>
                <Badge color="secondary" badgeContent={0}>
                    <IconButton
                        className={classes.notiBtn} 
                        color="inherit" onClick={openNotidrawer}>
                        <Notifications/>
                    </IconButton>
                </Badge>
            </Toolbar>
        </AppBar>
    );
}

export default Appbar;