import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import 'moment/locale/es';

import ContextsProviders from "@internal/wrappers/ContextsProviders/ContextsProviders";
import { SnackbarProvider } from "notistack";
import LoadingSplash from '@internal/wrappers/Loading/LoadingSplash';

ReactDOM.render(
    <SnackbarProvider maxSnack={3}>
        <ContextsProviders>
            <LoadingSplash>
                <App />
            </LoadingSplash>
        </ContextsProviders>
    </SnackbarProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
