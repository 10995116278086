import React, { useState, useMemo } from 'react';

const ConfigContext = React.createContext();

export const ConfigProvider = (props) => {

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState("Home");
    
    const startLoading = () => setLoading(true); 
    const stopLoading = () => setLoading(false); 

    const value = useMemo(() => ({
        loading,
        startLoading,
        stopLoading,
        currentPage,
        setCurrentPage
    }), [loading, currentPage]);

    return <ConfigContext.Provider value={value} {...props} />
}

export const useConfigContext = () => { 
    const context = React.useContext(ConfigContext);
    
    if (!context) { 
        throw new Error("useConfigContext() must be inside of ConfigProvider")
    }

    return context;
}