import React, { useState } from "react";

import {
    Dialog, DialogTitle, Grid, MenuItem,
    makeStyles, FormControl, InputLabel, Select, Button
} from "@material-ui/core";

import Autoform from "@internal/components/AutoForm/AutoFrom";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        padding: theme.spacing(2),
    },
    dialog: {
        width: "100%",
        maxWidth: "700px",
    },

    button: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(2),
    },
}));

const EntranceForm = ({ data, onSubmit, entrances=[] }) => {
    const classes = useStyles();

    const [entrance, setEntrance] = useState("");

    const submitForm = () => {
        if(entrance !== "" && entrance !== data.code) {
            onSubmit(entrance);
        }
    }

    return (
        <Autoform name="EntraceForm" className={classes.form} onSubmit={submitForm}>
            <Grid container spacing={3}
                justify="flex-end"
                className={classes.container}>
                <Grid item xs={12}>
                    <h2> {`${data.code} - ${data.name}`} </h2>
                </Grid>

                <Grid item xs={12}>
                    <p> ¿Dónde reasignamos las temperaturas? </p>
                </Grid>          
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" required>
                        <InputLabel id="entrance-label"> Punto de control </InputLabel>

                        <Select
                            value={entrance}
                            onChange={({ target }) => { setEntrance(target.value) }}
                            label="Punto de control"
                            labelId="entrance-label">

                            <MenuItem value="" disabled> Punto de control </MenuItem>
                            {entrances.map(entranceM => (
                                <MenuItem key={entranceM.code} value={entranceM.code}>
                                    {entranceM.name}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        onClick={()=>submitForm()}
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        disabled={entrance === "" || entrance === data.code}
                        fullWidth>
                        Eliminar
                    </Button>
                </Grid>
            </Grid>
        </Autoform>
    
    );
};

const DeleteEntranceDialog = ({ open, setOpen, data = {}, onSubmit, entrances }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{ className: classes.dialog }}>
            <DialogTitle> Eliminar punto de acceso </DialogTitle>
            <EntranceForm
                entrances={entrances}
                onSubmit={(newHolder) => {
                    setOpen(false);
                    onSubmit(data, newHolder);
                }}
                data={data}/>
        </Dialog>
    );
};

export default DeleteEntranceDialog;
