import React, { useState } from 'react';

import { InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';


const PasswordInput = ({ ...rest}) => {
    const [isVisible, setVisibility] = useState(false);
    const Icon = isVisible ? VisibilityOff : Visibility;
    
    return (
        <TextField
            type={isVisible ? "text" : "password"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Icon color="action"
                            style={{ cursor: "pointer" }}
                            onClick={() => { setVisibility(!isVisible) }} />
                    </InputAdornment>
                )
            }}
            {...rest} />
    );
}

export default PasswordInput;