import React, { useEffect } from 'react';

import { getRoleNumber } from '@internal/helpers/roles';

import { useUserContext } from '@internal/contexts/user.context';
import { useConfigContext } from '@internal/contexts/config.context';


export const withRole = (Component, role) => { 
    return ({title, ...props }) => { 
        const { user } = useUserContext();
        const { setCurrentPage } = useConfigContext();

        const priority = getRoleNumber(role);
        const userPriority = getRoleNumber(user.role);

        useEffect(() => { 
            setCurrentPage(title);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])

        if (userPriority >= priority) {
            return <Component {...props}/>;
        } else {
            //TODO: make loading component
            return <></>;
        }
    }
}

export const withRoleRender = (Component, role) => {
    return ({ ...rest }) => {
        const { user } = useUserContext();
        
        const priority = getRoleNumber(role);
        const userPriority = getRoleNumber(user.role);

        if (userPriority >= priority) {
            return <Component {...rest}/>;
        } else {
            //TODO: make loading component
            return <></>;
        }
    }
}