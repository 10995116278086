import React, { useState } from 'react';

import {
    TextField, FormControlLabel, Switch, InputLabel, FormHelperText,
    Grid, Button, FormControl, Select, MenuItem, InputAdornment, IconButton,
    makeStyles
} from '@material-ui/core';

import { 
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';

import { FaThermometer, FaQrcode, FaDoorOpen } from 'react-icons/all';
import { Save, Queue as AddToQueue, Business } from '@material-ui/icons';
 
import AutoForm from '@internal/components/AutoForm/AutoFrom';

import DateFnsUtils from '@date-io/date-fns';
import { isValid } from 'date-fns';

import { KEYS } from "@internal/constants"

const codeRegex = new RegExp("^(([A-Za-z]{2}[0-9]{5})|([0-9]{8})|([0-9]{8}-[0-9])|((PEC|pec)[0-9]{8}))$");

const useStyles = makeStyles((theme) => ({ 
    button: { 
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem"
    },
    iconInBtn: {
        marginLeft: theme.spacing(1)
    },
    helperText: {
        color: theme.palette.error.main
    }
}))

const TempForm = ({ entrances, onSubmit, onQueue, data = {} }) => {
    const classes = useStyles();

    const [code, setCode] = useState(data.code || "");
    const [temperature, setTemperature] = useState(data.temperature || "");
    const [datetime, setDatetime] = useState(data.datetime || null);
    const [comment, setComment] = useState(data.comment || "");
    const [entrance, setEntrance] = useState(data.entrance || "");
    const [organization, setOrganization] = useState(data.organization || "");
    const [maskGiven, setMaskGiven] = useState(data.maskGiven || false);

    const errors = {
        code: !(codeRegex.test(code)),
        temperature: isNaN(parseFloat(temperature)),
        datetime: datetime && isValid(datetime)? false : true,
        entrance: entrance === "",
        organization: organization === "",
    };

    let disabled = false;

    Object.keys(errors).forEach(key => { 
        if (errors[key]) { 
            disabled = true;
        }
    });

    const resetFields = () => { 
        setCode("");
        setTemperature("");
        setDatetime(null);
        setComment("");
        setEntrance("");
        setOrganization("");
        setMaskGiven(false);
    }

    const submitForm = (toQueue = false) => {
        if (disabled) {
            return;
        }

        const dataToSubmit = {
            id: data.id,
            code: code.toUpperCase(),
            temperature: parseFloat(temperature),
            timestamp: datetime.toISOString(),
            comment,
            maskGiven,
            entrance,
            organization
        };

        if (toQueue) {
            onQueue({...dataToSubmit});
        } else { 
            onSubmit({ ...dataToSubmit });
        }

        resetFields();
    };

    return (
        <AutoForm onSubmit={()=> submitForm(false)}>
            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                <Grid container spacing={3} justify="flex-end">
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            helperText={errors.code ? "Ingrese un código válido" : ""}
                            FormHelperTextProps={{ className: classes.helperText }}
                            value={code}
                            onChange={({target}) => setCode(target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{pointerEvents:"none"}}>
                                        <IconButton tabIndex={-1} >
                                            <FaQrcode/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            label="Código de usuario"
                            variant="outlined" />
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            helperText={errors.temperature ? "Ingrese una temperatura válida" : ""}
                            FormHelperTextProps={{ className: classes.helperText }}
                            value={temperature}
                            onChange={({target}) => setTemperature(target.value)}
                            type="number"
                            InputProps={{
                                inputProps: { min: 0 },
                                endAdornment: (
                                    <InputAdornment position="end" style={{pointerEvents:"none"}}>
                                        <IconButton tabIndex={-1}>
                                            <FaThermometer/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            label="Temperatura"
                            variant="outlined" />
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <KeyboardDateTimePicker
                            onKeyDown={(e)=>{
                                if(e.ctrlKey && e.code === "Space"){
                                    setDatetime(new Date());
                                }
                            }}
                            ampm={false}
                            fullWidth
                            required
                            helperText={errors.datetime ? "Ingrese una fecha válida" : ""}
                            FormHelperTextProps={{ className: classes.helperText }}
                            format="dd/MM/yyyy HH:mm"
                            label="Fecha y hora"
                            inputVariant="outlined"
                            value={datetime}
                            onChange={setDatetime}
                            contentEditable={false} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel id="entrance-label"> Punto de control </InputLabel>

                            <Select
                                value={entrance}
                                onChange={({ target }) => { setEntrance(target.value) }}
                                label="Punto de control"
                                endAdornment={<span></span>}
                                IconComponent={() => (
                                    <InputAdornment position="end" style={{pointerEvents:"none"}}>
                                        <IconButton tabIndex={-1}>
                                            <FaDoorOpen/>
                                        </IconButton>
                                    </InputAdornment>
                                )}
                                labelId="entrance-label">

                                <MenuItem value="" disabled> Punto de control </MenuItem>
                                {entrances.map(entranceM => (
                                    <MenuItem key={entranceM.code} value={entranceM.code}>
                                        {entranceM.name}
                                    </MenuItem>
                                ))}

                            </Select>

                            {errors.entrance && <FormHelperText className={classes.helperText}> Selecciona una entrada </FormHelperText> }
                        </FormControl>  
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel id="oraganization-label"> Institución </InputLabel>

                            <Select
                                value={organization}
                                onChange={({ target }) => { setOrganization(target.value) }}
                                label="Institución"
                                endAdornment={<span></span>}
                                IconComponent={() => (
                                    <InputAdornment position="end" style={{pointerEvents:"none"}}>
                                        <IconButton tabIndex={-1}>
                                            <Business/>
                                        </IconButton>
                                    </InputAdornment>
                                )}
                                labelId="oraganization-label">

                                <MenuItem value="" disabled> Institución </MenuItem>
                                {KEYS.ORGANIZATIONS.map(org => (
                                    <MenuItem key={org.code} value={org.code}>
                                        {org.title}
                                    </MenuItem>
                                ))}

                            </Select>

                            {errors.organization && <FormHelperText className={classes.helperText}> Selecciona una Institución </FormHelperText> }
                        </FormControl>  
                    </Grid>
                    
                    <Grid item xs={12} md={6} style={{display: "flex"}}> 
                        <FormControlLabel
                            required
                            control={
                                <Switch
                                    color="primary"
                                    checked={maskGiven}
                                    onChange={({ target }) => setMaskGiven(target.checked)} />
                            }
                            label="¿Se entregó mascarilla?"/>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            multiline
                            fullWidth
                            value={comment}
                            onChange={({target}) => setComment(target.value)}
                            InputProps={{
                                inputProps: {
                                    rows: 5
                            }}}
                            label="Comentario..."
                            variant="outlined" />
                    </Grid>

                    {/* Botones */}
                    {onQueue && <Grid item xs={12} md={6}>
                        <Button
                            disabled={disabled}
                            className={classes.button}
                            onClick={() => {submitForm(true)}}
                            endIcon={<AddToQueue className={classes.iconInBtn} />}
                            fullWidth color="secondary" variant="outlined">
                            En cola
                        </Button>
                    </Grid>}

                    <Grid item xs={12} md={6}>
                        <Button
                            disabled={disabled}
                            className={classes.button}
                            onClick={()=>{submitForm()}}
                            endIcon={<Save className={classes.iconInBtn} />}
                            fullWidth color="primary" variant="contained">
                            Guardar
                        </Button>
                    </Grid>
                    
                </Grid>
            </MuiPickersUtilsProvider>
        </AutoForm>
    );
}

export default TempForm;