import React, { useState } from "react";

import {
    Dialog, DialogTitle, Grid,
    makeStyles, TextField, FormControlLabel,
    Switch, Button,
} from "@material-ui/core";

import Autoform from "@internal/components/AutoForm/AutoFrom";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        padding: theme.spacing(2),
    },
    dialog: {
        width: "100%",
        maxWidth: "700px",
    },

    button: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(2),
    },
}));

const EntranceForm = ({ data, onSubmit, onToggle, isNew }) => {
    const classes = useStyles();

    const [name, setName] = useState(data.name || "");
    const [allowsPeople, setAllowsPeople] = useState(data.allowsPeople || false);
    const [allowsVehicles, setAllowsVehicles] = useState(data.allowsVehicles|| false) ;
    const [photo, setPhoto] = useState(data.photo || "");
    const [image, setImage] = useState("");

    const [isURL, setIsUrl] = useState(true);

    const submitForm = () => {
        const formData = new FormData();

        formData.append("name", name);
        formData.append("allowsPeople", allowsPeople);
        formData.append("allowsVehicles", allowsVehicles);

        if (isURL) {
            photo !== "" && formData.append("photo", photo);
        } else { 
            formData.append("image", image);
        }

        if (!isNew) formData.append("id", data._id);

        onSubmit(formData, isNew)
    }

    //Image input
    const imagePicker = (
        <Button
            className={classes.button}
            fullWidth
            color="primary"
            component="label"
            variant="outlined">
            Elige el archivo
            <input
                onChange={({ target }) => {
                    setImage(target.files[0])
                }}
                name="image"
                type="file"
                hidden accept="image/jpeg, image/png" />
        </Button>
    );

    const urlTextField = (
        <TextField
            name="photo"
            value={photo}
            onChange={({target}) => { setPhoto(target.value) }}
            label="Enlace"
            variant="outlined" fullWidth />
    );

    const photoInput = isURL ? urlTextField : imagePicker;

    //Choose button
    const submitButton = (
        <Grid item xs={12} md={6}>
            <Button
                onClick={submitForm}
                className={classes.button}
                variant="contained"
                fullWidth   
                color="primary">
                {isNew ? "Crear" : "Actualizar" }
            </Button>
        </Grid>
    );

    const activateButton = (
        <Grid item xs={12} md={6}>
            <Button
                onClick={()=> onToggle(data.code, data.active)}
                className={classes.button}
                variant="outlined"
                fullWidth
                color="secondary">
                {data.active ? "Desactivar" : "Activar"}
            </Button>
        </Grid>
    );

    return (
        <Autoform name="EntraceForm" className={classes.form} onSubmit={submitForm}>
            <Grid container spacing={3}
                justify="flex-end"
                className={classes.container}>
                <Grid item xs={12}>
                    <TextField
                        name="name"
                        value={name}
                        onChange={({target}) => { setName(target.value) }}
                        label="Nombre del Acceso"
                        variant="outlined" fullWidth />
                </Grid>

                <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                    <FormControlLabel
                        required
                        control={
                            <Switch
                                name="allowsPeople"
                                color="primary"
                                checked={allowsPeople}
                                onChange={({target}) => { setAllowsPeople(target.checked) }}
                            />
                        }
                        label="¿Entrada peatonal?"
                    />
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                    <FormControlLabel
                        required
                        control={
                            <Switch
                                name="allowsVehicles"
                                color="primary"
                                checked={allowsVehicles}
                                onChange={({target}) => { setAllowsVehicles(target.checked) }}
                            />
                        }
                        label="¿Entrada vehicular?"
                    />
                </Grid>

                <Grid
                    container
                    item
                    xs={12} sm={8}
                    justify="center"
                    alignItems="center"
                >
                    {photoInput}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid
                        container
                        justify="center"
                        spacing={1}
                        component="label">
                        <Grid justify="center" container item xs={12}>
                            Tipo de imagen
                        </Grid>
                        <Grid xs={12}
                            alignItems="center"
                            justify="center"
                            wrap="nowrap"
                            style={{ display: "flex" }}
                            item container>
                            <Grid item> Archivo </Grid>
                            <Grid item>
                                <Switch
                                    checked={isURL}
                                    onChange={() => {
                                        setIsUrl(!isURL);
                                    }}
                                />
                            </Grid>
                            <Grid item> Enlace </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!isNew && activateButton}
                {submitButton}
            </Grid>
        </Autoform>
    
    );
};

const EntranceDialog = ({ open, setOpen, data = {}, onSubmit, toggleActive, isNew }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{ className: classes.dialog }}>
            <DialogTitle> Punto de Acceso </DialogTitle>
            <EntranceForm
                onSubmit={(formData, isNewEntrance) => {
                    setOpen(false);
                    onSubmit(formData, isNewEntrance)
                }}
                onToggle={(code, active) => { 
                    setOpen(false);
                    toggleActive(code, active);
                }}
                data={data}
                isNew={isNew}/>
        </Dialog>
    );
};

export default EntranceDialog;
