import React from 'react';

import { Grid, withStyles, Paper } from '@material-ui/core';
import { FaThermometerFull, FaStopwatch } from 'react-icons/all';

import QRCode from 'qrcode.react';
import TempsTable from '@internal/components/TempsTable/TempsTable';

const MaxGrid = withStyles((theme) => ({ 
    root: { 
        maxWidth: theme.breakpoints.values.xl,
        minHeight: "70vh",
        margin: "0 auto",
        "& p": {
            fontSize: "1.2rem",
            margin: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&>*": { margin: theme.spacing(0, 2) }
        },
        "& h2": {
            fontSize: "1.4rem",
            textAlign: "center",
            margin: theme.spacing(1)
        },
        "& time": {
            fontFamily: "Montserrat",
            fontWeight: "regular"
        }
    }
}))(Grid);

const VCenterGrid = withStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "&.not": {
            "& p": {
                margin: theme.spacing(1,0),
            }
        }
    }
}))(Grid);

const Panel = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    }
}))(Grid);

const PanelContainer = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    }
}))(Grid)

const UserTempInfo = ({ own, user, temperatures, limit, page, pages, myStats, startDate, endDate, onChangeLimit, onChangePage, onChangeStartDate, onChangeEndDate, onRefreshTable }) => {
    return (
        <MaxGrid container justify="center">
            <PanelContainer item container
                xs={12} lg={6} >
                <Panel container component={Paper}
                    justify="center"
                    alignContent="center"
                    alignItems="center">
                    <VCenterGrid item xs={12} className="not">
                        <QRCode
                            size={256}
                            renderAs="svg"
                            value={user.code} />
                        <p> {user.code} </p>
                        { user.firstnames && user.lastnames &&
                            <p> {`${user.firstnames} ${user.lastnames}`} </p>
                        }
                        <p> {user.organization} </p>
                    </VCenterGrid>

                    <VCenterGrid item xs={12} md={6}>
                        <h2> Temperatura promedio </h2>
                        <p> <FaThermometerFull/> {Number(myStats.average).toFixed(1)} °C </p>
                    </VCenterGrid>
                    
                    <VCenterGrid item xs={12} md={6}>
                        <h2> Temperaturas registradas </h2>
                        <p> {myStats.count} <FaStopwatch/> </p>
                    </VCenterGrid>
                    
                    <VCenterGrid item xs={12}>
                        <p> {new Date().toLocaleDateString("es",  { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} </p>
                    </VCenterGrid>
                </Panel>
            </PanelContainer>
            <PanelContainer item container
                xs={12} lg={6} >
                <Panel container component={Paper}
                    justify="center"
                    alignContent="center"
                    alignItems="center">

                    <TempsTable
                        own={own}
                        elevation={0}
                        limit={limit}
                        page={page}
                        pages={pages}
                        startDate={startDate}
                        endDate={endDate}
                        onRefresh={onRefreshTable}
                        onChangeLimit={onChangeLimit}
                        onChangePage={onChangePage}
                        onChangeStartDate={onChangeStartDate}
                        onChangeEndDate={onChangeEndDate}
                        temperatures={temperatures} />

                </Panel>
            </PanelContainer>
        </MaxGrid>
    );
}

export default UserTempInfo;