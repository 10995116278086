import React, { useState } from 'react';

import { Dialog, DialogTitle, Button } from '@material-ui/core';

import PasswordField from '@internal/components/PasswordInput/PasswordInput';
import AutoForm from '@internal/components/AutoForm/AutoFrom';

import classes from './NPDialog.module.css'
import { useUserContext } from '@internal/contexts/user.context';

const NPDialog = ({ open }) => {
    const [password, setPassword] = useState("");
    const [passwordR, setPasswordR] = useState("");

    const { setNewPassword, newPassError, noPassNeeded } = useUserContext();

    const submitForm = () => setNewPassword(password);

    const samePasswordError = {
        status: false,
        msg: ""
    }

    if (password !== passwordR) { 
        samePasswordError.status = true;
        samePasswordError.msg = "Las contraseñas deben de coincidir";
    }

    const passwordIncorrect = {
        status: false,
        msg: ""
    };

    switch (newPassError) {
        case 400:
            passwordIncorrect.status = true;
            passwordIncorrect.msg = "Formato de contraseña incorrecto";
            break;
        default:
            break;
    }

    return (
        <Dialog open={open} transitionDuration={250} onClose={noPassNeeded}>
            <DialogTitle id="pass-choose"> Establezca una contraseña </DialogTitle>
            <AutoForm onSubmit={submitForm} className={classes.form}>
                <PasswordField
                    error={passwordIncorrect.status}
                    helperText={passwordIncorrect.msg}
                    variant="outlined"
                    label="Nueva Contraseña"
                    onChange={({target}) => setPassword(target.value) }
                    value={password} />
                
                <PasswordField
                    error={samePasswordError.status}
                    helperText={samePasswordError.msg}
                    variant="outlined"
                    label="Repite Contraseña"
                    onChange={({target}) => setPasswordR(target.value) }
                    value={passwordR} />
                
                <Button
                    disabled={samePasswordError.status || password === ""}
                    color="primary"
                    onClick={() => submitForm()}
                    variant="contained">
                    Establecer contraseña
                </Button>
            </AutoForm>
        </Dialog>
    );
}

export default NPDialog;