import { KEYS } from '@internal/constants';

export const getTempQueue = () => { 
    const stringQueue = localStorage.getItem(KEYS.TEMPQUEUE);

    if (stringQueue) {
        return JSON.parse(stringQueue);
    } else {
        return [];
     }
};

export const setTempQueue = (queue) => { 
    const stringQueue = JSON.stringify(queue);
    localStorage.setItem(KEYS.TEMPQUEUE, stringQueue);
}

export const removeTempQueue = () => localStorage.removeItem(KEYS.TEMPQUEUE);