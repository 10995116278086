import React from "react";
import "./App.css";

import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import PrivateRoute from '@internal/wrappers/PrivateRoute/PrivateRoute';

import Login from '@internal/pages/Login/Login';
import NotFound from '@internal/pages/NotFound/NotFound';
import Main from "@internal/pages/Main/Main";

function App() {
    return (
        <ThemeProvider theme={mainTheme}>
            <Router basename="/">
                <Switch>
                    <Route component={Login} path="/login" exact />
                    <PrivateRoute path = "/app">
                        <Main/>
                    </PrivateRoute>
                    <Route path="/" exact>
                        <Redirect to="/app"/>
                    </Route>
                    <Route>
                        <NotFound isFullPage />
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    );
}

const mainTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#10427a",
            light: "#1063bf",
            //dark: "#10427a",
        },
        secondary: {
            main: "#FF5722"
        }
    }
});

export default App;