import React, {useState, useEffect} from 'react';

import { withRole } from '@internal/hoc/roles';
import { useConfigContext } from '@internal/contexts/config.context';
import { useUserContext } from '@internal/contexts/user.context';
import { getTodayAt } from '@internal/helpers/date';

import UserTempInfo from '@internal/components/UserInfo/UserTempInfo';

import { ROLES, API } from '@internal/constants';

const Home = props => {
    const [myStats, setMyStats] = useState({ count: 0, average: 0 });
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [startDate, setStartDate] = useState(getTodayAt(6));
    const [endDate, setEndDate] = useState(getTodayAt(18));
    const [temperatures, setTemperatures] = useState([]);

    const { startLoading, stopLoading } = useConfigContext();
    const { token, user } = useUserContext();

    const getTemperatures = async () => {
        startLoading();
        const queries = new URLSearchParams({
            page: page - 1, limit, startDate: startDate.toISOString(), endDate: endDate.toISOString()
        });
        const url = new URL(`${API.BASEURL}/temp/id`)

        url.search = queries.toString();

        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`,
            }
        });

        if (response.ok) {
            const { data, pages } = await response.json();
            setTemperatures(data);
            setPages(pages);
        }

        stopLoading();
    };

    const fetchMyStats = async () => {
        startLoading();
        
        const queries = new URLSearchParams({
            startDate: getTodayAt(0).toISOString(), endDate: getTodayAt(23).toISOString()
        });
        const url = new URL(`${API.BASEURL}/stats/my-stats`)
        url.search = queries.toString();

        const response = await fetch(url, {
            headers: { authorization: `Bearer ${token}` }
        })

        if (response.ok) {
            const stats = await response.json();
            setMyStats(stats);
        }

        stopLoading();
    }

    useEffect(() => {
        getTemperatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, page]);

    useEffect(() => {
        setPage(1);
    }, [limit]);

    useEffect(() => {
        fetchMyStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <UserTempInfo
            own
            onChangePage={setPage}
            onChangeLimit={setLimit}
            onChangeStartDate={setStartDate}
            onChangeEndDate={setEndDate}
            onRefreshTable={getTemperatures}
            myStats={myStats}
            user={user}
            startDate={startDate}
            endDate={endDate}
            page={page}
            pages={pages}
            limit={limit}
            temperatures={temperatures}/>
    ); 
}

export default withRole(Home, ROLES.USER);