import React, { useState, useEffect, useRef } from 'react';

import { withRole, withRoleRender } from '@internal/hoc/roles';
import { ROLES, API } from '@internal/constants';
import { getTodayAt } from '@internal/helpers/date';
import moment from 'moment';

import TempsTable from '@internal/components/TempsTable/TempsTable';
import TempsReport from '@internal/components/Reports/TempsReport/TempsReport';

import { useConfigContext } from '@internal/contexts/config.context';
import { useUserContext } from '@internal/contexts/user.context';

import { useReactToPrint } from 'react-to-print';

import { Grid, withStyles, Fab } from '@material-ui/core';
import { Print } from '@material-ui/icons';

const MaxGrid = withStyles((theme) => ({ 
    root: { 
        maxWidth: theme.breakpoints.values.lg,
        margin: "0 auto"
    }
}))(Grid);

const FloatingButton = withRoleRender(withStyles((theme) => ({
    root: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
 }))(Fab), ROLES.REPORTERPLUS);

const Temperatures = props => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [startDate, setStartDate] = useState(getTodayAt(6));
    const [endDate, setEndDate] = useState(getTodayAt(18));
    const [temperatures, setTemperatures] = useState([]);

    const [tempsToReport, setTempsToReport] = useState([]);
    const [isPrinting, setIsPrinting] = useState(false);

    const { startLoading, stopLoading } = useConfigContext();
    const { token } = useUserContext();
    const reportRef = useRef()

    const sendToPrint = useReactToPrint({
        content: () => reportRef.current,
        onAfterPrint: () => {
            setIsPrinting(false);
            setTempsToReport([])
        },
        documentTitle: `CheckinUCA_temps_${moment(startDate).format("DD-MM-yy_HH-mm")}_to_${moment(endDate).format("DD-MM-yy_HH-mm")}`,
    });
    
    const getTemperatures = async () => {
        startLoading();
        const queries = new URLSearchParams({
            page: page - 1, limit, startDate: startDate.toISOString(), endDate: endDate.toISOString()
        });
        const url = new URL(`${API.BASEURL}/temp/all`)

        url.search = queries.toString();

        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`,
            }
        });

        if (response.ok) {
            const { data, pages } = await response.json();
            setTemperatures(data);
            setPages(pages);
        }

        stopLoading();
    };

    const getTemperaturesForReport = async () => {
        startLoading();
        const queries = new URLSearchParams({
            limit, startDate: startDate.toISOString(), endDate: endDate.toISOString(),
            woutPagination: true
        });
        const url = new URL(`${API.BASEURL}/temp/all`)

        url.search = queries.toString();

        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`,
            }
        });

        if (response.ok) {
            const temps = await response.json();
            setTempsToReport(temps.reverse());
        }

        stopLoading();
    };

    useEffect(() => {
        getTemperatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, page]);

    useEffect(() => {
        setPage(1);
    }, [limit]);

    //Handlers
    const toPrintHandler = async () => {
        await getTemperaturesForReport();
        setIsPrinting(true);
        sendToPrint();
    }
    
    return (
        <MaxGrid container justify="center" alignItems="center">
            <TempsTable
                temperatures={ temperatures }
                page={page}
                pages={pages}
                limit={limit}
                startDate={startDate}
                endDate={endDate}
                onChangeStartDate={setStartDate}
                onChangeEndDate={setEndDate}
                onRefresh={getTemperatures}
                onChangeLimit={setLimit}
                onChangePage={setPage} />
            
            <FloatingButton color="primary" onClick={toPrintHandler}>
                <Print/>
            </FloatingButton>
            
            {isPrinting && <div style={{ overflow: "hidden", height: 0 }}>
                <TempsReport ref={reportRef} temperatures={tempsToReport} startDate={startDate} endDate={endDate}/>
            </div>}
        </MaxGrid>
    );
}

export default withRole(Temperatures, ROLES.REPORTER);