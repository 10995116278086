import React, {useRef} from 'react';

const AutoFrom = ({ onSubmit, children, ...rest }) => {
    const form = useRef()
    return (
        <form
            ref={form}
            onSubmit={(e) => { e.preventDefault() }} onKeyDown={(e) => {
            if (e.ctrlKey && e.key === "Enter") { 
                onSubmit(form.current);
            }
        }} {...rest}>
            { children }
        </form>
    );
}

export default AutoFrom;