import React, { useState, useEffect } from 'react';

import { withRole } from '@internal/hoc/roles';
import { ROLES, API } from '@internal/constants';
import { getTodayAt } from '@internal/helpers/date';

import TempsTable from '@internal/components/TempsTable/TempsTable';
import { useConfigContext } from '@internal/contexts/config.context';
import { useUserContext } from '@internal/contexts/user.context';

import { Grid, withStyles } from '@material-ui/core';

const MaxGrid = withStyles((theme) => ({ 
    root: { 
        maxWidth: theme.breakpoints.values.lg,
        margin: "0 auto"
    }
}))(Grid);

const MyTemperatures = props => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [startDate, setStartDate] = useState(getTodayAt(6));
    const [endDate, setEndDate] = useState(getTodayAt(18));
    const [temperatures, setTemperatures] = useState([]);

    const { startLoading, stopLoading } = useConfigContext();
    const { token } = useUserContext();
    
    const getTemperatures = async () => {
        startLoading();
        const queries = new URLSearchParams({
            page: page - 1, limit, startDate: startDate.toISOString(), endDate: endDate.toISOString()
        });
        const url = new URL(`${API.BASEURL}/temp/id`)

        url.search = queries.toString();

        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`,
            }
        });

        if (response.ok) {
            const { data, pages } = await response.json();
            setTemperatures(data);
            setPages(pages);
        }

        stopLoading();
    };

    useEffect(() => {
        getTemperatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, page]);

    useEffect(() => {
        setPage(1);
    }, [limit]);
    
    return (
        <MaxGrid container justify="center" alignItems="center">
            <TempsTable
                own
                temperatures={ temperatures }
                page={page}
                pages={pages}
                limit={limit}
                startDate={startDate}
                endDate={endDate}
                onChangeStartDate={setStartDate}
                onChangeEndDate={setEndDate}
                onRefresh={getTemperatures}
                onChangeLimit={setLimit}
                onChangePage={setPage} />
        </MaxGrid>
    );
}

export default withRole(MyTemperatures, ROLES.USER);