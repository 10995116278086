import React, { useState } from 'react';

import {
    List, ListItem, ListItemSecondaryAction, ListItemText,
    Divider, makeStyles, Typography, IconButton, Dialog, DialogTitle,
    Grid, Button
} from '@material-ui/core';
import { Delete, Edit, Sync } from '@material-ui/icons';
import Moment from 'react-moment';

import TempForm from '@internal/components/TempForm/TempForm';

const useStyles = makeStyles((theme) => ({ 
    list: {
        maxHeight: "50vh",
        width: "100%",
        maxWidth: 600,
        margin: "0 auto"
    },
    listItem: {
        minWidth: 250
    },
    listItemTitle: {
        margin: theme.spacing(2, 0)
    },
    data: {
        display: "block"
    },
    dialog: {
        padding: theme.spacing(2)
    },
    dialogTitle: {
        margin: theme.spacing(2, 0),
    },
    iconBtn: {
        marginLeft: theme.spacing(1)
    }
}))

const TempQueue = ({ queue, onSync, entrances, onUpdate, onDelete }) => {
    const classes = useStyles();
    const [updating, setUpdating] = useState(false);
    const [dataToUpdate, setData] = useState({});

    const queueList = queue.map(log => (
        <React.Fragment key={`${log.code}_${log.timestamp}`}>
            <ListItem className={classes.listItem}>
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography component="span" variant="body2" className={classes.data}>
                                {`Temp: ${log.temperature}° C - ${entrances[entrances.findIndex(entrance => entrance.code === log.entrance)].name}`}
                            </Typography>
                            <Typography component="span" variant="body2" className={classes.data}>
                                {`Mascarilla: ${log.maskGiven ? "Sí" : "No"}`}
                            </Typography>
                            <Moment
                                local
                                fromNow>{log.timestamp}</Moment>
                        </React.Fragment>
                    }
                    primaryTypographyProps={{className: classes.listItemTitle, variant: "h6"}}
                    primary={log.code} />
                <ListItemSecondaryAction>
                    <IconButton
                        onClick={() => {
                            setData({ ...log, datetime: new Date(log.timestamp) });
                            setUpdating(true);
                        }}
                        edge="end" aria-label="edit">
                        <Edit/>
                    </IconButton>
                    <IconButton
                        onClick={()=>onDelete(log.id) }
                        edge="end" aria-label="delete">
                        <Delete/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider/>
        </React.Fragment>
    ));

    let content = (
        <List className={classes.list}>
            <Divider />
            { queueList}
        </List>
    );

    if (queue.length <= 0) { 
        content = <h3 style={{ textAlign: "center", margin: "1rem" }}> Añade algunas temperaturas </h3>
    }

    return (
        <React.Fragment>
            <Grid container spacing={3} justify="flex-end">
                <Grid item xs={12}>
                    { content }
                </Grid>

                <Grid item xs={12} md={6}>
                    <Button
                        disabled={queue.length <= 0}
                        onClick={onSync}
                        style={{padding: "1rem"}}
                        color="secondary"
                        variant="outlined"
                        endIcon={<Sync className={classes.iconBtn}/>}
                        fullWidth>
                        Sincronizar
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                maxWidth="md" open={updating}
                onClose={() => {
                    setData({});
                    setUpdating(false);
                }}
                PaperProps={{ className: classes.dialog }}>

                <DialogTitle className={classes.dialogTitle}> Editar temperatura </DialogTitle>
                <TempForm
                    onSubmit={(data) => { 
                        onUpdate(data);
                        setData({});
                        setUpdating(false);
                    } }
                    entrances={entrances}
                    data={dataToUpdate} />

            </Dialog>
        </React.Fragment>
    );
}

export default TempQueue;