import React from 'react';
import { NavLink } from 'react-router-dom';

import {
    SwipeableDrawer as Drawer, IconButton, List,
    ListItem, ListItemIcon, ListItemText,
    Divider, makeStyles, Typography
} from '@material-ui/core';

import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: "100%",
        maxWidth: 450,
        flexShrink: 0
    },
    drawerPaper: {
        width: "100%",
        maxWidth: 450
    },
    header: {
        display: "flex",
        color: "#fff",
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        userSelect: "none",
        padding: theme.spacing(2),
        alignItems: "center"
    },
    closeIcon: {
        color: "#fff",
        marginRight: theme.spacing(2)
    },
    link: {
        display: "flex",
        textDecoration: "none",
        color: theme.palette.text.primary
    },
    current: {
        "&>div": {
            backgroundColor: theme.palette.action.selected
        }
    }
}));

const NotificationDrawer = ({ notifications, open, closeDrawer, openDrawer }) => {
    const classes = useStyles();
    
    return (
        <Drawer
            onClose={closeDrawer}
            onOpen={openDrawer}
            open={open}
            variant="temporary"
            anchor="right"
            classes={{paper: classes.drawerPaper}}
            className={classes.drawer}>
            
            <header className={classes.header}>
                <IconButton onClick={closeDrawer} className={classes.closeIcon}>
                    <ArrowForward/>
                </IconButton>

                <h2>
                    Notificaciones
                </h2>
            </header>

        </Drawer>
    );
}

export default NotificationDrawer;