import React, { useState } from 'react';

import Appbar from '@internal/components/Appbar/Appbar';
import MainDrawer from '@internal/components/MainDrawer/MainDrawer';
import NotificationDrawer from "@internal/components/NotificationDrawer/NotificationDrawer";

import {  makeStyles } from '@material-ui/core';

import { useUserContext } from '@internal/contexts/user.context';
import { useConfigContext } from '@internal/contexts/config.context';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
    },
    content: {
        flexGrow: 1,
        display: "flex",
        minWidth: "0",
        overflow: "auto",
        padding: theme.spacing(0.5),
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(3),
        }
    }
}));



const MainPageWrapper = ({ children, menus }) => {
    const classes = useStyles();
    const { user, logout } = useUserContext();
    const { currentPage } = useConfigContext();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [notidrawerOpen, setNotidrawerOpen] = useState(false);
    
    return (
        <div className={ classes.root } >
            <Appbar
                title={currentPage}
                code={user.code}
                logout={logout}
                openNotidrawer={()=> setNotidrawerOpen(true)}
                openDrawer={() => setDrawerOpen(true)} />
            <MainDrawer
                menusByRole={menus}
                user={user}
                open={drawerOpen}
                openDrawer={() => setDrawerOpen(true)}
                closeDrawer={() => setDrawerOpen(false)} />

            <NotificationDrawer
                menusByRole={menus}
                user={user}
                open={notidrawerOpen}
                openDrawer={() => setNotidrawerOpen(true)}
                closeDrawer={() => setNotidrawerOpen(false)} />
            
            <main className={classes.content} >
                { children }
            </main>
        </div>
    );
}

export default MainPageWrapper;