import React from 'react';

import EntranceStatItem from '@internal/components/EntranceStatItem/EntranceStatItem';

const EntranceList = ({entrancesStats = []}) => {
    return entrancesStats.map((entranceStat) => {
        const { entrance, count } = entranceStat;
        return (
            <EntranceStatItem
                key={`${entrance.code}-${entrance.name}`}
                {...entrance}
                count={count}/>
        ); 
    });
}

export default EntranceList;