import React, { useEffect, useState } from 'react';

import { Fab, withStyles } from '@material-ui/core';
import { Add as AddIcon } from "@material-ui/icons";
    
import { useConfigContext } from '@internal/contexts/config.context';
import { useUserContext } from '@internal/contexts/user.context';
import { useSnackbar } from 'notistack';

import { withRole } from '@internal/hoc/roles';
import { ROLES, API } from '@internal/constants';

import EntranceTiles from '@internal/components/EntranceTiles/EntranceTiles';
import EntranceDialog from '@internal/components/EntranceDialog/EntranceDialog';
import DeleteEntranceDialog from '@internal/components/EntranceDialog/DeleteEntranceDialog/DeleteEntranceDialog';

const FloatingButton = withStyles((theme) => ({
    root: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
 }))(Fab);

const EntrancesControl = props => {
    const [entrances, setEntrances] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isNewEntrance, setIsNewEntrance] = useState(true);
    const [currentEntrance, setCurrentEntrance] = useState({});
    const [currentEntranceDelete, setCurrentEntranceDelete] = useState({});

    const { startLoading, stopLoading } = useConfigContext();
    const { token } = useUserContext();
    const { enqueueSnackbar } = useSnackbar();

    const fetchEntrances = async () => { 
        startLoading();
    
        const queries = new URLSearchParams({
            page: 0, limit: 100
        });

        const url = new URL(`${API.BASEURL}/entrance/all`);
        url.search = queries.toString()

        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`
            }
        });

        if (response.ok) { 
            const { data } = await response.json();
            data.sort((a, b) => a.name.localeCompare(b.name));
            setEntrances(data);
        }

        stopLoading();
    }

    const createOrUpdateEntrance = async (formData, isNew) => { 
        startLoading();
        let url = null;
        const msg = isNew ? "creado" : "actualizado"

        if (isNew) {
            url = new URL(`${API.BASEURL}/entrance/register`);
        } else { 
            url = new URL(`${API.BASEURL}/entrance/update`);
        }
        
        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`
            },
            body: formData,
            method: "POST"
        })

        if (response.ok) {
            enqueueSnackbar(`Punto de acceso ${msg}`, { variant: "success" });
        } else { 
            enqueueSnackbar(`Punto de acceso no ${msg}`, { variant: "error" });
        }

        fetchEntrances();
        stopLoading();
    }

    const toggleActiveInEntrance = async (code, activeValue) => { 
        startLoading();

        let url = null;
        const body = JSON.stringify({ code });
        const msg = activeValue ? "desactivado" : "activado";

        if (activeValue) {
            url = new URL(`${API.BASEURL}/entrance/deactivate`);
        } else { 
            url = new URL(`${API.BASEURL}/entrance/activate`);
        }

        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`,
                "content-type": "application/json"
            },
            method: "POST",
            body: body,
        })

        if (response.ok) {
            enqueueSnackbar(`Punto de acceso ${msg}`, { variant: "success" });
        } else { 
            enqueueSnackbar(`Punto de acceso no ${msg}`, { variant: "error" });
        }

        fetchEntrances();
        stopLoading();
    };

    const deleteEntrance = async ({code}, newHolder) => {
        startLoading();

        const url = new URL(`${API.BASEURL}/entrance/delete`);
        const body = JSON.stringify({code, newHolder});

        const response = await fetch(url, {
            method: "POST",
            headers:{
                authorization: `Bearer ${token}`,
                "content-type": "application/json",     
            },
            body: body
        });
        
        if (response.ok) {
            enqueueSnackbar(`Punto de acceso Eliminado`, { variant: "success" });
        } else { 
            enqueueSnackbar(`Punto de acceso no Eliminado`, { variant: "error" });
        }

        fetchEntrances();

        stopLoading();
    }

    useEffect(() => {
        fetchEntrances();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <EntranceTiles
                openDialog={(entrance) => {
                    setOpenDialog(true);
                    setIsNewEntrance(false);
                    setCurrentEntrance(entrance)
                }}
                openDeleteDialog={(entrance)=>{
                    setOpenDeleteDialog(true);
                    setCurrentEntranceDelete(entrance);
                }}
                entrances={entrances} />
            <EntranceDialog
                open={openDialog}
                onSubmit={createOrUpdateEntrance}
                toggleActive={toggleActiveInEntrance}
                data={currentEntrance}
                isNew={isNewEntrance}
                setOpen={setOpenDialog} />

            <DeleteEntranceDialog
                open={openDeleteDialog}
                onSubmit={deleteEntrance}
                setOpen={setOpenDeleteDialog}
                data={currentEntranceDelete}
                entrances={entrances}
            />
            <FloatingButton
                aria-label="add-entrance"
                onClick={()=> {
                    setOpenDialog(true);
                    setIsNewEntrance(true);
                    setCurrentEntrance({});
                }}
                color="primary">
                <AddIcon/>
            </FloatingButton>
        </>
    );
}

export default withRole(EntrancesControl, ROLES.ADMIN);