import React, { useState, useEffect } from 'react';

import { Grid, withStyles, Paper } from '@material-ui/core';

import { getTodayAt } from '@internal/helpers/date';
import { withRole } from '@internal/hoc/roles';

import { useUserContext } from '@internal/contexts/user.context';
import { useConfigContext } from '@internal/contexts/config.context';

import StartEndDate from '@internal/components/StartEndDate/StartEndDate';
import DashStat from '@internal/components/DashStat/DashStat';

import EntranceList from './EntranceList/EntranceList';
import TempChart from './TempChart/TempChart';

import { ROLES, API } from '@internal/constants'; 

const MainContainer = withStyles(theme => ({
    root: {
        width: "100%",
    }
}))(Grid);

const EntranceContainer = withStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        overflowY: "auto"
    }
}))(Grid);

const DashCompoment = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    }
}))(Grid);

const Stats = props => {
    const [startDate, setStartDate] = useState(getTodayAt(6));
    const [endDate, setEndDate] = useState(getTodayAt(18));

    const [entrancesStats, setEntranceStats] = useState([]);
    
    const [avgTemps, setAvgTemps] = useState(0);
    const [countTemps, setCountTemps] = useState(0);
    const [countPeople, setCountPeople] = useState(0);

    const [temperatures, setTemperatures] = useState([]);

    const { token } = useUserContext();
    const { startLoading, stopLoading } = useConfigContext();

    /**
     * API Method 
     */

    const fetchEntrancesStats = async () => { 
        const url = new URL(`${API.BASEURL}/stats/entrances-people`);
        const query = new URLSearchParams({
            startDate: startDate.toISOString(), endDate: endDate.toISOString()
        });

        url.search = query.toString();

        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`
            }
        });

        if(response.ok) {
            const data = await response.json();
            data.sort((a, b) => a.entrance.name.localeCompare(b.entrance.name));
            setEntranceStats(data);
        }

    }

    const fetchGeneralStats = async () => {
        const url = new URL(`${API.BASEURL}/stats/general`);
        const query = new URLSearchParams({
            startDate: startDate.toISOString(), endDate: endDate.toISOString()
        });

        url.search = query.toString();
        
        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`
            }
        });

        if (response.ok) {
            const { avgTemperature, countTemperature, peopleCount } = await response.json();

            setAvgTemps(avgTemperature);
            setCountTemps(countTemperature);
            setCountPeople(peopleCount);
        }
    }

    const fetchTemperatures = async () => {
        const url = new URL(`${API.BASEURL}/temp/all`);
        const query = new URLSearchParams({
            startDate: startDate.toISOString(), endDate: endDate.toISOString(), woutPagination: true
        });

        url.search = query.toString();
        
        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`
            }
        });

        if (response.ok) {
            const temperaturesData = await response.json();
            setTemperatures(temperaturesData.reverse());
        }
    }

    const fetchAllStats = async () => { 
        startLoading();

        await fetchEntrancesStats();
        await fetchGeneralStats();
        await fetchTemperatures();

        stopLoading();
    }

    /** Effects Hooks */

    useEffect(() => {
        fetchAllStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MainContainer container component={Paper}>
            {/** TODO: Tabs área -> Dashboard */}

            <DashCompoment item container xs={12} sm={4}>
                <DashStat title="Control de entradas" isContainer>
                    <EntranceContainer item xs={12}>
                        <EntranceList entrancesStats={entrancesStats} />
                    </EntranceContainer>
                </DashStat>
            </DashCompoment>

            <DashCompoment item container xs={12} sm={8} justify="center" >
                <DashStat sm={4} title="Personas registradas" info={countPeople}/>
                <DashStat sm={4} title="Temperaturas registradas" info={countTemps}/>
                <DashStat sm={4} title="Temperatura promedio" info={`${Number(avgTemps).toFixed(1)} °`} />
                
                <DashStat title="Gráfico de temperaturas">
                    <Grid item xs={12}>
                        <TempChart temperatures={temperatures}/>
                    </Grid>
                </DashStat>
                
                <DashStat>
                    <StartEndDate
                        justify="flex-end"
                        onRefresh={() => { fetchAllStats() }}
                        startDate={startDate}
                        onChangeStartDate={setStartDate}
                        endDate={endDate}
                        onChangeEndDate={setEndDate}/>
                </DashStat>
            </DashCompoment>
        </MainContainer>
    );
}

export default withRole(Stats, ROLES.REPORTER);