import React from 'react';

import { Grid, Avatar, withStyles, makeStyles } from "@material-ui/core";
import { MeetingRoom } from '@material-ui/icons'; 

const Icon = withStyles(theme => ({
    root: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.primary.main,
    }
}))(Avatar);

const Container = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        margin: theme.spacing(1, 0),
        fontSize: 14,
        "&>*": {
            padding: theme.spacing(1)
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 12,
            padding: theme.spacing(1)
        } 
    }
}))(Grid)

const useStyles = props => makeStyles(theme => ({
    container: {
        opacity: props.active ? 1 : 0.5
    },
    count: {
        textAlign: "end"
    }
}));

const EntranceStatItem = ({ code, name, photo, active, count }) => {
    const classes = useStyles({ active })();

    return (
        <Container container className={classes.container}>
            <Grid item xs={2} container justify="center" alignItems="center">
                <Icon alt={`${code}-${name}`} src={photo} >
                    <MeetingRoom/>
                </Icon>
            </Grid>

            <Grid item xs={8} sm={6} container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <h3> {name} </h3>
                </Grid>
            </Grid>
            
            <Grid className={classes.count} item xs={2} sm={4} container alignItems="center" justify="flex-end">
                <Grid item xs={12}> <h4> Personas </h4> </Grid>
                <Grid item xs={12}> <p> { count } </p> </Grid>
            </Grid>
        </Container>
    )
}

export default EntranceStatItem;