const constants = {};

constants.TOKEN = "auth-token";
constants.GOOGLEID = process.env.REACT_APP_GOOGLEID || "46497582734-uv0f7dihq4kknh785b7g3cbktfmuf0cj.apps.googleusercontent.com";
constants.TEMPQUEUE = "temp-queue";
constants.ORGANIZATIONS = [
    { title: 'UCA', code: "UCA"  },
    { title: 'Fundación Gondra', code: "FG" },
    { title: "Visitante", code: "VISITOR" }
];
constants.USER = "auth-user";

export default constants;