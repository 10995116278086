import React, {memo} from 'react';

import {
    TableContainer, Table, TableBody, TableRow,
    TableCell, TableHead, Paper, makeStyles,
    Grid, Tooltip, Zoom, Select, MenuItem,
    withStyles
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import Moment from 'react-moment';

import StartEndDate from '@internal/components/StartEndDate/StartEndDate';

const columns = [
    //"Código", "Institución", "Temperatura", "Punto de control", "Tomada por", "¿Mascarilla?", "Comentario", "Fecha"
    { id: "code", label: "Código", width: "15ch", ownOmit: true },
    { id: "organization", label: "Institución", width: "10ch", ownOmit: true },
    { id: "temperature", label: "Temperatura", width: "10ch" },
    { id: "entrance", label: "Punto de control", width: "30ch" },
    { id: "takenBy", label: "Tomada por", width: "15ch" },
    { id: "timestamp", label: "Fecha", width: "30ch" },
    { id: "comment", label: "Comentario", width: "60ch" },
];

const limits = [2, 10, 20, 50, 100];

const useStyles = makeStyles((theme) => ({
    gridItem: {
        margin: theme.spacing(2),
    },
    alertRow: {
        "&>*": {
            color: theme.palette.error.main
        }
    },
    pagination: {
        maxWidth: theme.breakpoints.values.sm
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: props => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        "&:hover": {
            backgroundColor: theme.palette.action.selected,
        }
    }),
  }))(TableRow);

//MAIN COMPONENT

const TempsTable = ({ own, elevation=3, fulldate, timeOfRefresh, page, limit = 10, pages = 5, startDate, endDate, onChangeStartDate, onChangeEndDate, onChangePage, onChangeLimit, onRefresh, temperatures = [] }) => {
    const classes = useStyles();

    const headers = columns.map(column => { 
        if (own) {
            if (column.ownOmit) return undefined;
        }

        return (
            <StyledTableCell align="center" style={{ width: column.width }} key={column.id}>
                { column.label }
            </StyledTableCell>
        )
    });

    const CustomPaper = (props) => <Paper {...props} elevation={elevation}> { props.children } </Paper>

    const temperaturesMapped = temperatures.map((temperature, index) => (
        <StyledTableRow
            className={temperature.alert? classes.alertRow : ""}
            key={`${temperature.code}_${temperature.timestamp}_${Math.random()}`}>
            <TableCell align="center"> {((page-1)*limit) + (index +1)} </TableCell>
            {!own && <TableCell align="center"> {temperature.code} </TableCell>}
            {!own && <TableCell align="center"> {temperature.organization} </TableCell>}
            <TableCell align="center"> {Number(temperature.temperature).toFixed(1)}° C </TableCell>
            <TableCell align="center"> {temperature.entrance} </TableCell>
            <TableCell align="center"> {temperature.takenBy} </TableCell>
            <TableCell align="center">
                <Tooltip
                    interactive
                    TransitionComponent={Zoom}
                    title={new Date(temperature.timestamp).toLocaleString()}>
                    { fulldate? <Moment format="DD/MM/yyyy HH:mm" local>{temperature.timestamp}</Moment>
                        : <Moment fromNow local>{temperature.timestamp}</Moment> }
                </Tooltip>
            </TableCell>
            <TableCell align="justify"> {temperature.comment} </TableCell>
        </StyledTableRow>
    ));
    
    return (
        <Grid container justify="center" component={CustomPaper} style={{ width: "100%" }}>
            <Grid item xs={12}>
                <TableContainer style={{maxHeight: "70vh", minHeight: 440, flex: "1 0"}}>
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center" style={{ width: "5ch" }}> # </StyledTableCell>
                                { headers }
                            </TableRow>
                        </TableHead>
        
                        <TableBody>
                            { temperaturesMapped }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <StartEndDate
                xs={12} md={6}
                justify="center"
                time={timeOfRefresh}
                onRefresh={onRefresh}
                startDate={startDate}
                endDate={endDate}
                onChangeEndDate={onChangeEndDate}
                onChangeStartDate={onChangeStartDate}/>
                
            <Grid xs={12} md={6} item container justify="center" alignItems="center">
                <Grid item className={classes.gridItem}>
                    <Pagination
                        className={classes.pagination}
                        onChange={(e, page) => { onChangePage(page) } }
                        color="primary"
                        shape="rounded"
                        boundaryCount={1}
                        size="small"
                        showFirstButton
                        showLastButton
                        page={Number(page)}
                        count={Number(pages)} />
                </Grid>
                <Grid item className={classes.gridItem}>
                    <Select
                        onChange={({target}) => { onChangeLimit(target.value) }}
                        value={limit}
                        label="Cantidad" >
                        {limits.map(limitM => (
                            <MenuItem key={`Limit_${limitM}`} value={limitM}>
                                {limitM} Filas
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default memo(TempsTable);