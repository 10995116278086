import React, { useState, useEffect } from 'react';

import { Grid, withStyles, TextField, Select, FormControl, InputLabel, Button, Paper, MenuItem, Avatar } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import { KEYS } from '@internal/constants';

import TempsTable from '@internal/components/TempsTable/TempsTable';

import { getTodayAt } from "@internal/helpers/date";

import { useUserContext } from "@internal/contexts/user.context";
import { useConfigContext } from "@internal/contexts/config.context";
import { useSnackbar } from "notistack";
import { useLocation } from 'react-router-dom';

import { ROLES, API } from "@internal/constants";
import { withRole } from '@internal/hoc/roles';

const Panel = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        "&>div": {
            padding: theme.spacing(1)
        }
    }
}))(Grid)

const MaxContainer = withStyles(theme => ({
    root: {
        maxWidth: theme.breakpoints.values.lg,
        margin: "0 auto"
    }
}))(Grid)

const PanelContainer = withStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    }
}))(Grid)

const Info = withStyles(theme => ({
    root: {
        fontSize: 14,
        "&>h4": {
            margin: theme.spacing(0.7, 0)
        }
    }
}))(Grid);

//data:image/png;base64,

const CheckPerson = (props) => {
    const [organization, setOrganization] = useState("UCA");
    const [code, setCode] = useState("");
    const [user, setUser] = useState({});

    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [startDate, setStartDate] = useState(getTodayAt(6));
    const [endDate, setEndDate] = useState(getTodayAt(18));
    const [temperatures, setTemperatures] = useState([]);

    const { startLoading, stopLoading } = useConfigContext();
    const { token } = useUserContext();
    const { enqueueSnackbar } = useSnackbar();
    const { state } = useLocation();

    const fetchUserInfo = async (codeGiven=undefined, orgGiven=undefined) => {
        if(!codeGiven && code === "")  return;

        startLoading();

        const queries = new URLSearchParams({
            code: codeGiven ? codeGiven : code, organization: orgGiven ? orgGiven : organization
        });
        const url = new URL(`${API.BASEURL}/user/info`)

        url.search = queries.toString();

        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`,
            }
        });

        if(response.ok) {
            const data = await response.json();
            setUser(data);

            enqueueSnackbar("Usuario encontrado", {variant: "success"});
        } else {
            if(response.status === 404) {
                enqueueSnackbar("Usuario no encontrado", {variant: "warning"});
            }
        }

        stopLoading();
    }
    
    const fetchUserTemperatures = async () => {
        if(user._id === undefined)  return;

        startLoading();

        const queries = new URLSearchParams({
            page: page - 1, limit, startDate: startDate.toISOString(), endDate: endDate.toISOString()
        });
        const url = new URL(`${API.BASEURL}/temp/id/${user._id}`)

        url.search = queries.toString();

        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`,
            }
        });

        if (response.ok) {
            const { data, pages } = await response.json();
            setTemperatures(data);
            setPages(pages);
        }

        stopLoading();
    }

    useEffect(()=>{
        fetchUserTemperatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, page, user]);

    useEffect(()=> {

        const { code: codeGiven, organization: orgGiven } = state || {};

        if (codeGiven && orgGiven) {
            setCode(codeGiven);
            setOrganization(orgGiven);
            fetchUserInfo(codeGiven, orgGiven)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Handlers

    const onSubmitHandler = async () => {
        await fetchUserInfo();
    }

    const onRefreshHandler = async () => {
        fetchUserTemperatures();
    }

    const onFastSubmitHandler = (e) => {
        if (e.ctrlKey && e.key === "Enter"){
            onSubmitHandler();
        }
    }

    return (
        <MaxContainer container direction="column">
             <PanelContainer item container>
                <Panel item container xs={12} component={Paper} alignItems="center">
                    <Grid item container xs={12} md={5}>
                        <Grid item container xs={3} justify="center" alignItems="center">
                            {user._id && <Avatar style={{width: 64, height: 64}}
                                src={`data:image/png;base64,${user.photo}`} /> }
                        </Grid>
                        <Info item xs={9}>
                            {user.firstnames && user.lastnames && <h4> {`${user.firstnames} ${user.lastnames}`} </h4>}
                            {user.code && <h4>{`${user.code}`}</h4>}
                            {user.organization && <h4>{`${KEYS.ORGANIZATIONS.find(({code}) => code === user.organization).title}`}</h4>}
                            {user.job && <h4>{`${user.job}`}</h4>}
                        </Info>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            required
                            onKeyDown={onFastSubmitHandler}
                            label="Código"
                            variant="outlined"
                            value={code}
                            onChange = {({target}) => setCode(target.value)}
                            fullWidth />
                    </Grid>

                    <Grid item xs={12} md={3}>
                    <FormControl fullWidth variant="outlined" required>
                            <InputLabel id="oraganization-label"> Institución </InputLabel>

                            <Select
                                value={organization}
                                onChange={({ target }) => { setOrganization(target.value) }}
                                label="Institución"
                                labelId="oraganization-label">

                                <MenuItem value="" disabled> Institución </MenuItem>
                                {KEYS.ORGANIZATIONS.map(org => (
                                    <MenuItem key={org.code} value={org.code}>
                                        {org.title}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={1}>
                        <Button
                            onClick={onSubmitHandler}
                            color="primary"
                            fullWidth>
                           <Search style={{marginRight: 16}} /> Buscar
                        </Button>
                    </Grid>
                </Panel>
             </PanelContainer> 

             <PanelContainer item container>
                <Panel container item xs={12} component={Paper}>
                    <TempsTable
                        own
                        fullDate
                        elevation={0}
                        onRefresh={onRefreshHandler}
                        onChangeEndDate={setEndDate}
                        onChangeLimit={setLimit}
                        onChangePage={setPage}
                        onChangeStartDate={setStartDate}
                        temperatures={temperatures}
                        startDate={startDate}
                        endDate={endDate}
                        pages={pages}
                        limit={limit} 
                        page={page}/>
                </Panel>
             </PanelContainer>
        </MaxContainer>
    );
}

export default withRole(CheckPerson, ROLES.REPORTERPLUS);