import React from 'react';

import { useUserContext } from '@internal/contexts/user.context';
import { Redirect, withRouter, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, children, path, location, ...rest }) => {
    const { token } = useUserContext();

    const body = children ? children : <Component />;
    const toRender = token ? body : <Redirect to={{ state: { from: location }, pathname: "/login"}} />;

    return (
        <Route path={path} {...rest}>
            {toRender}
        </Route>
    );
}

export default withRouter(PrivateRoute);