import React from 'react';

import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { useConfigContext } from '@internal/contexts/config.context';

const useStyles = makeStyles((theme) => ({ 
    backdrop: {
        zIndex: theme.zIndex.drawer+1
    }
}));

const LoadingSplash = ({ children }) => {
    const classes = useStyles();
    const { loading } = useConfigContext();

    return (
        <>
            { children }
            <Backdrop open={loading} className={classes.backdrop}>
                <CircularProgress color="primary"/>
            </Backdrop>
        </>
    );
}

export default LoadingSplash;