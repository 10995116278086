import React from 'react';

import { Switch, Route, Redirect} from 'react-router-dom';

import { ROLES } from '@internal/constants';
import { getRoleNumber } from '@internal/helpers/roles';

import { Person, Security, Settings, MultilineChart, MeetingRoom, Add, MyLocation, Home as HomeIcon, GraphicEq, PermContactCalendar } from '@material-ui/icons';

import MainPageWrapper from '@internal/wrappers/MainPageWrapper/MainPageWrapper';
import TempRegister from './TempRegister/TempRegister';
import MyTemperatures from './MyTemperatures/MyTemperatures';
import Temperatures from './Temperatures/Temperatures';
import Home from './Home/Home'; 
import EntrancesControl from './EntrancesControl/EntrancesControl';
import Stats from './Stats/Stats'
import CheckPerson from './CheckPerson/CheckPerson';

const dummy = () => (<div></div>)

const menusByRoles = {
    [getRoleNumber(ROLES.SYSADMIN)]: [
        { title: "Control de Usuarios", path: "/app/users", icon: Person, component: dummy },
        { title: "Configuración del sitema", path: "/app/settings", icon: Settings, component: dummy   }
    ],
    [getRoleNumber(ROLES.ADMIN)]: [
        { title: "Control de Vigilantes", path: "/app/vigilants", icon: Security, component: dummy },
        { title: "Control de Entradas", path: "/app/access-points", icon: MeetingRoom, component: EntrancesControl },
    ],
    [getRoleNumber(ROLES.REPORTERPLUS)]: [
        { title: "Consultar persona", path: "/app/check-person", icon: PermContactCalendar, component: CheckPerson },
    ],
    [getRoleNumber(ROLES.VIGILANT)]: [
        { title: "Ingresar Temperatura", path: "/app/temperature", icon: Add, component: TempRegister},
    ],
    [getRoleNumber(ROLES.REPORTER)]: [
        { title: "Registro de Temperaturas", path: "/app/temperatures-log", icon: MultilineChart, component: Temperatures },
        { title: "Estadísticas", path: "/app/stats", icon: GraphicEq, component: Stats },
    ],
    [getRoleNumber(ROLES.USER)]: [
        { title: "Inicio", path: "/app/home", icon: HomeIcon, component: Home},
        { title: "Mis Temperaturas", path: "/app/my-temperatures", icon: MyLocation, component: MyTemperatures},
    ],
};


const Main = props => {
    const pages = [];

    Object.keys(menusByRoles).forEach(key => {
        menusByRoles[key].forEach(item => { 
            const page = (
                <Route key={item.path} path={item.path} exact>
                    <item.component title={item.title}/>
                </Route>
            );
            pages.push(page);
        });
    });

    return (
        <MainPageWrapper menus={menusByRoles}>
            <Switch>
                {pages}
                <Route>
                    <Redirect to="/app/home" />
                </Route>
            </Switch>
        </MainPageWrapper>
    );
}

export default Main;