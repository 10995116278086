import React from 'react';

import { UserProvider } from '@internal/contexts/user.context';
import { ConfigProvider } from '@internal/contexts/config.context';
import { NotificationProvider } from '@internal/contexts/notification.context';

const Contexts = ({ children }) => {
    return (
        <ConfigProvider>
            <NotificationProvider>
                <UserProvider>
                    {children}
                </UserProvider>
            </NotificationProvider>
        </ConfigProvider>
    );   
}

export default Contexts;