import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: "center",
        margin: theme.spacing(1, 0)
    },
    temperature: {
        fontSize: theme.typography.h3.fontSize
    },
    container: {
        overflowY: "auto",
        flex: "1 0 300px"
    }
}));

const DashStat = ({ title, info, isContainer, children, ...rest }) => {
    const classes = useStyles();
    
    return (
        <Grid item container xs={12} justify="flex-start" direction="column" {...rest}>
            <h3 className={classes.title}> { title } </h3>
            <Grid className={isContainer ? classes.container : ""} item container justify="center" alignItems="center">
                {children ? children :
                    <span className={classes.temperature}>
                        { info }
                    </span> }
            </Grid>
        </Grid>
    );
}

export default DashStat;